import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import {NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderRouterModule, SPINNER} from 'ngx-ui-loader';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './header/header.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {FormsModule} from '@angular/forms';




@NgModule({
    declarations: [LayoutComponent, HeaderComponent, SidebarComponent],
  exports: [
    HeaderComponent,
    SidebarComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule
    ]
})
export class LayoutsModule { }
