import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './shared/auth.guard';
import {LayoutComponent} from './layouts/layout/layout.component';
import { NotAccessibleComponent } from './not-accessible/not-accessible.component';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'forgot-password', component: ForgetPasswordComponent},
  {path: 'set-password', component: ForgetPasswordComponent},
  {path: '', component: LoginComponent},
  { path: '', component: LayoutComponent, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},
  { path: '', component: LayoutComponent, loadChildren: () => import('./restaurants/restaurants.module').then(m => m.RestaurantsModule), canActivate: [AuthGuard] },
  {path: 'notfound', component: NotFoundComponent},
  {path: 'not-accessible', component: NotAccessibleComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: '/notfound'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
