import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../shared/data.service';
import {Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
const momentTz = require('moment-timezone');

declare var $: any;
declare var Chart: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentZone: any;
  currentZoneName = '2';
  userType = '';
  currentTime: any;
  get userData(): any {
    return JSON.parse(localStorage.getItem('user')) || [];
  }
  constructor(private dataService: DataService, private router: Router) {
    window.scrollTo(0, 0);
  }

  // Logout function start
  logout() {
    window.localStorage.setItem('isAuthenticated', 'false');
    window.localStorage.setItem('token', '');
    window.localStorage.setItem('user_type', '');
    window.localStorage.setItem('restaurant_name', '');
    window.localStorage.setItem('time_zone', '');
    window.localStorage.setItem('permissions', '[]');
    window.localStorage.setItem('user', '[]');
    this.router.navigate(['/login']);
  }
  updatePassword(){
    this.router.navigate(['/update-password']);
  }
  updateTimeZone(value) {
    window.localStorage.setItem('time_zone', value);
    window.location.reload();
  }
  // Logout function end

  ngOnInit() {
    this.currentZone = JSON.parse(localStorage.getItem('time_zone')) || 2;
    this.userType = JSON.parse(localStorage.getItem('user_type')) || '';

    if ( this.currentZone === 1 ) {
      this.currentZoneName = 'Canada/Atlantic';
    } else if ( this.currentZone === 2 ) {
      this.currentZoneName = 'Canada/Central';
    } else if ( this.currentZone === 3 ) {
      this.currentZoneName = 'Canada/Eastern';
    } else if ( this.currentZone === 4 ) {
      this.currentZoneName = 'Canada/Mountain';
    } else if ( this.currentZone === 5 ) {
      this.currentZoneName = 'Canada/Newfoundland';
    } else if ( this.currentZone === 6 ) {
      this.currentZoneName = 'Canada/Pacific';
    } else if ( this.currentZone === 7 ) {
      this.currentZoneName = 'Canada/Saskatchewan';
    } else if ( this.currentZone === 8 ) {
      this.currentZoneName = 'Canada/Yukon';
    }
    this.currentTime = momentTz.tz(moment(), this.currentZoneName).format('YYYY-MM-DDTHH:mm:ss.SSS');
    $.getScript('../../assets/js/jquery.app.js');
    $('#open-left').click(function() {
      $.getScript('../../assets/js/jquery.app.js');
    });
  }

  ngOnDestroy() {
  }


}
