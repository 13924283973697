import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../shared/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {EventsService} from 'angular4-events';
import {map, take} from 'rxjs/operators';

declare var $: any;
declare var toastr: any;
declare var grecaptcha: any;


@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  password = '';
  confirm_password = '';
  id: any;
  error = '';
  response = '';
  current_url = '';
  constructor(private dataService: DataService, private router: Router,
              private ngxService: NgxUiLoaderService, private events: EventsService,
              private route: ActivatedRoute,
  ) {
    window.scrollTo(0, 0);
    if (window.localStorage.getItem('isAuthenticated') === 'true') {
      this.router.navigate([JSON.parse(localStorage.getItem('permissions'))[0].url]);
    }
    if ( this.router.url === '/login') {
      router.navigate(['/']);
    }
    this.id = this.route.snapshot.queryParamMap.get('id');
    console.log(this.id);
    if ( !this.id ) {
      router.navigate(['/login']);
    }
    // this.current_url = this.router.url.split('?')[0];
    // console.log('current_url', this.current_url);
  }


  updatePassword() {
    var form = $('#restaurantForgotPass');
    form.parsley().validate();
    if (form.parsley().isValid()) {
      if (this.password !== this.confirm_password) {
        toastr.error('Password and Conform Password must be match');
        return;
      }
      this.error = '';
      this.ngxService.start();
      this.dataService.forgotPasswordUpdate(this.id, this.password).subscribe(
        (data) => {
          this.ngxService.stop();
          this.router.navigate(['/login']);
          toastr.success('password update successfully');
        },
        (error) => {
          this.ngxService.stop();
          this.error = error.error.message;
        }
      );
    }
  }
  checkKeyValid(){
    this.ngxService.start();
    this.dataService.checkPasswordKeyValid(this.id).subscribe(
      (data) => {
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
        this.error = error.error.message;
        // this.router.navigate(['/login']);
      }
    );
  }
  ngOnInit() {
    this.checkKeyValid();
    $('form').parsley();
    // has uppercase validation
    window.Parsley.addValidator('uppercase', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var uppercases = value.match(/[A-Z]/g) || [];
        return uppercases.length >= requirement;
      },
      messages: {
        en: 'Your password must contain at least (%s) uppercase letter.'
      }
    });

    // has lowercase validation
    window.Parsley.addValidator('lowercase', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var lowecases = value.match(/[a-z]/g) || [];
        return lowecases.length >= requirement;
      },
      messages: {
        en: 'Your password must contain at least (%s) lowercase letter.'
      }
    });

    // has number validation
    window.Parsley.addValidator('number', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var numbers = value.match(/[0-9]/g) || [];
        return numbers.length >= requirement;
      },
      messages: {
        en: 'Your password must contain at least (%s) number.'
      }
    });

    // has special char validation
    window.Parsley.addValidator('special', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var specials = value.match(/[^a-zA-Z0-9]/g) || [];
        return specials.length >= requirement;
      },
      messages: {
        en: 'Your password must contain at least (%s) special characters.'
      }
    });
  }
  ngOnDestroy(): void {

  }

}

