import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../shared/data.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {EventsService} from 'angular4-events';
import {map, take} from 'rxjs/operators';

declare var grecaptcha: any;
declare var firebase: any;
declare var context: any;
declare var toastr: any;
declare var $: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  user_name = '';
  password = '';
  userData: any;
  error = '';
  captcha = '';
  response = '';
  forgotEmail = '';
  deviceId = '';
  constructor(private dataService: DataService, private router: Router,
              private ngxService: NgxUiLoaderService, private events: EventsService) {
    window.scrollTo(0, 0);
    if (window.localStorage.getItem('isAuthenticated') === 'true') {
      this.router.navigate([JSON.parse(localStorage.getItem('permissions'))[0].url]);
    }
    if ( this.router.url === '/login') {
      router.navigate(['/']);
    }
    // grecaptcha.reset();
  }


  // Login function start
  login() {
    this.error = '';
    // if ( this.user_name === '' || this.password === '' ) {
    //   return;
    // }
    if (this.user_name === '') {
      toastr.error('Enter username');
      return;
    }
    if (this.password === '') {
      toastr.error('Enter password');
      return;
    }
    this.captcha = $('#recaptureMain');
    this.response = grecaptcha.getResponse();
    if (this.response.length === 0) {
      toastr.error('Please check recaptcha');
      return;
    }
    this.ngxService.start();
    this.dataService.signUp(this.user_name, this.password).subscribe(
      (data) => {
        this.ngxService.stop();
        this.userData = data;
        window.localStorage.setItem('isAuthenticated', 'true');
        window.localStorage.setItem('token', this.userData.token);
        window.localStorage.setItem('user_type', this.userData.user_type);
        window.localStorage.setItem('restaurant_name', this.userData.restaurant_name);
        window.localStorage.setItem('permissions', JSON.stringify(this.userData.permissions));
        window.localStorage.setItem('user', JSON.stringify(this.userData));
        window.localStorage.setItem('time_zone', this.userData.time_zone || '2');
        this.router.navigate([this.userData.permissions[0].url]);
        this.events.publish('permissions', true);
      },
      (error) => {
        this.ngxService.stop();
        this.error = error.error.message;
      }
    );
  }
  // Login function end
  forgotPasswordModal(){
    $('#forgotEmailModal').modal('show');
    this.forgotEmail = '';
  }
  forgotPasswordFun(){
    if (this.forgotEmail === '') {
      toastr.error('Enter email');
      return;
    }
    this.ngxService.start();
    this.dataService.forgotPassword(this.forgotEmail).subscribe(
      (data) => {
        $('#forgotEmailModal').modal('hide');
        this.ngxService.stop();
        toastr.success('Check your email account');
      },
      (error) => {
        this.ngxService.stop();
        toastr.error(error.error.message);
      }
    );
  }



  ngOnInit() {
    setTimeout(() => {
      grecaptcha.render('recaptura12');
    }, 1000);
  }
  ngOnDestroy(): void {}

}
